import React from 'react';
import { Form, Input, Button, message } from 'antd';

import { createTimeslot } from '../../api/api';

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
};

const styles = {
    note: {
        color: 'purple'
    }
}

const isValidDate = dateString => {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if(!dateString.match(regEx)) return false;  // Invalid format
    let d = new Date(dateString);
    let dNum = d.getTime();
    if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
    return d.toISOString().slice(0,10) === dateString;
}

const isFuture = dateString => {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if(!dateString.match(regEx)) return false;  // Invalid format
    let d = new Date(dateString+'T00:00:00');
    const now = (new Date()).getTime();
    return d >= now;
}

const isValidTime = dateString => {
    const regEx = /^\d{2}:\d{2}$/;
    if(!dateString.match(regEx)) return false;  // Invalid format

    const split = dateString.split(':');
    return split.length === 2 && !isNaN(split[0]) && !isNaN(split[1]);
}

const AddTimeslotAdminPage = props => {
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        console.log('Success:', values);

        try {
            const response = await createTimeslot(values);
            console.log('---', response);

            if (response?.data) {
                if (response.data.added === 'true' || response.data.added === true) {
                    message.success({
                        content: 'Added', 
                        style: { fontSize: '1.5em', backgroundColor: 'lightblue' },
                        duration: 5
                    });
                    onReset();
                } else {
                    message.error({
                        content: 'Something went wrong. Please check with Admin!', 
                        style: { fontSize: '1.5em', backgroundColor: 'red' },
                        duration: 5
                    });
                }
            }
        } catch (error) {
            // console.log('---e:', error?.response?.data);
            if (error?.response?.data) {
                message.error({
                    content: error.response.data.message, 
                    style: { fontSize: '1.5em', backgroundColor: 'red' },
                    duration: 5
                });
            }
        }
    };

    const onReset = () => {
        form.resetFields();
    };

    return (
        <Form
            {...layout}
            name="basic"
            initialValues={{
                remember: true,
            }}
            form={form}
            onFinish={onFinish}
        >
            <Form.Item
                label="Date"
                name="date"
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Please enter date (format: 2021-05-10)',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (isValidDate(value)) {
                                if (isFuture(value)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Please enter a future date!'));
                            }
                            return Promise.reject(new Error('format: 2021-05-10'));
                        },
                    })
                ]}
            >
                <Input />
            </Form.Item>
        
            <Form.Item
                label="Time"
                name="time"
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Please enter time (format: 09:00)',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (isValidTime(value)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('format: 09:00'));
                        },
                    })
                ]}
            >
                <Input />
            </Form.Item>
        
            <Form.Item {...tailLayout}>
                Note: <span style={styles.note}>Every timeslot is 15 minutes;</span>
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">Add</Button>
            </Form.Item>
        </Form>
    );
}

export default AddTimeslotAdminPage;