import React, { useState, useEffect } from 'react';

// import DisplaySubjectMap from '../DisplaySubjectMap';
// import OneScholarTimeslot from '../OneScholarTimeslot';
import Scholars from '../Scholars';

import { getScholarsRollup } from '../../api/api';

const styles = {
    scholars: {
        textAlign: 'center',
        width: '100%'
    },
    scholarAdmin: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    scholarTable: {
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'center',
        padding: 5,
        borderBottom: '1px solid lightgray'
    },
    pad: {
        flex: 1
    },
    name: {
        flex: 1,
        textAlign: 'right',
        color: 'purple'
    },
    content: {
        flex: 5,
        textAlign: 'left',
        marginLeft: 20
    },
    subjectsMap: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 20,
        padding: 5,
        width: '100%'
    },
    subjectsMapKey: {
        flex: 1,
        flexGrow: 1
    },
    subjectsMapValue: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft: 20,
        flex: 5,
        flexGrow: 1
    },
    subjectsMapVal: {
        marginRight: 15
    },
    subject: {
        marginLeft: 20
    },
    allSubject: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    timeslotMap: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 20,
        padding: 5,
        width: '100%'
    },
    timeslotMapKey: {
        flex: 1,
        flexGrow: 1
    },
    timeslotMapValue: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft: 20,
        flex: 5,
        flexGrow: 1
    },
    timeslotMapVal: {
        marginRight: 15
    },
    title: {
        fontWeight: 'bold'
    }
}

const ScholarAdminPage = props => {
    const [ allScholars, setAllScholars ] = useState(null);
    const [ selectedScholar, setSelectedScholar ] = useState(null);
    const [ allScholarsMap, setAllScholarsMap ] = useState(null);

    useEffect(() => {
        async function getAllScholars() {
            const response = await getScholarsRollup();
            const scholars = response?.data?.scholars;
            setAllScholars(scholars);

            const scholarMap = [{id: 0, ename: '...ALL...'}];
            Object.keys(scholars).forEach(key => {
                scholarMap.push(scholars[key]);
            });
            setAllScholarsMap(scholarMap);
        }
        getAllScholars();
    }, []);

    const buildSubjectMap = subject => {
        let map = null;
        subject.forEach(sub => {
            if (!map) {
                map = {};
            }
            if (!map[sub.parent]) {
                map[sub.parent] = [];
            }
            map[sub.parent].push(sub.name);
        });
        return map;
    }

    const buildTimeslot = timeslot => {
        let map = null;
        timeslot.forEach(time => {
            if (!map) {
                map = {};
            }
            if (!map[time.date]) {
                map[time.date] = [];
            }
            map[time.date].push(time.time);
        });
        return map;
    }

    const buildScholarName = scholar => {
        return (
            <div>
                { scholar.ename }
                { scholar.cname && (<div>{scholar.cname}</div>)}
            </div>
        )
    }

    const updateSelectedScholar = id => {
        resetAll();
        setTimeout(() => {
            const found = allScholarsMap.find(theScholar => theScholar.id === id);
            if (found) {
                const newSelectedScholar = Object.assign({}, found);
                setSelectedScholar(newSelectedScholar);
            } else {
                setSelectedScholar(null);
            }
        }, 200);
    }

    const resetAll = _ => {
        setSelectedScholar(null);
    }

    const buildTheScholarAdminPage = (scholarName, i) => {
        const oneScholar = allScholars[scholarName];
        const subjectsMap = buildSubjectMap(oneScholar.subject);
        const timeslotMap = buildTimeslot(oneScholar.timeslot);
        const name = buildScholarName(oneScholar);

        return (
            <div>
                <div key={i} style={styles.scholarTable}>
                    <div style={styles.pad}></div>
                    <div style={styles.name}>{name}</div>
                    <div style={styles.content}>
                        {
                            subjectsMap &&
                                <div style={styles.title}>Subjects:</div>
                        }
                        
                        {
                            subjectsMap && Object.keys(subjectsMap).map((key, y) => {
                                return (
                                    <div key={y} style={styles.subjectsMap}>
                                        <div style={styles.subjectsMapKey}>{key}</div>
                                        <div style={styles.subjectsMapValue}>
                                            {
                                                subjectsMap[key].length > 0 && subjectsMap[key].map((val, i) => <div key={i} style={styles.subjectsMapVal}>{val}</div>)
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }

                        {
                            timeslotMap &&
                                <div style={styles.title}>Timeslots:</div>
                        }
                        {
                            timeslotMap && Object.keys(timeslotMap).map((key, y) => {
                                return (
                                    <div key={y} style={styles.timeslotMap}>
                                        <div style={styles.timeslotMapKey}>{key}</div>
                                        <div style={styles.timeslotMapValue}>
                                            {
                                                timeslotMap[key].length > 0 && timeslotMap[key].map((val, i) => <div key={i} style={styles.timeslotMapVal}>{val}</div>)
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    
                    <div style={styles.pad}></div>
                </div>
            </div>
        )
    }

    return (
        <div style={styles.scholarAdmin}>
            {
                allScholarsMap &&
                    <div style={styles.scholars}>
                        <Scholars 
                            allScholars={allScholarsMap}
                            updateScholar={updateSelectedScholar}
                        />
                    </div>
            }
            {
                (!selectedScholar || selectedScholar.id === 0) && allScholars && Object.keys(allScholars).map((scholarName, i) => {
                    return buildTheScholarAdminPage(scholarName, i);
                })
            }
            {
                selectedScholar && selectedScholar.id !== 0 && buildTheScholarAdminPage(selectedScholar.ename, 0)
            }
        </div>
    )
}

export default ScholarAdminPage;