import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { message } from 'antd';

import Scholars from './Scholars';
import ShowSelectedScholar from './ShowSelectedScholar';
import DateTime from './DateTime';
import OneScholar from './OneScholar';
import OneScholarTimeslot from './OneScholarTimeslot';
import Logout from './Logout';

import { checkIfUserAlreadyLoggedIn, updateScholarTimeslot, logout } from '../utils/util';
import { getScholars } from '../api/api';

const styles = {
    home: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 50
    },
    settings: {
        position: 'absolute',
        top: 20,
        left: 20,
        fontSize: '2.0em'
    },
    exit: {
        position: 'absolute',
        top: 20,
        right: 20,
        fontSize: '2.0em',
        cursor: 'pointer'
    },
    logout: {
        textAlign: 'center',
        marginTop: 20
    },
    notes: {
        marginTop: 10,
        padding: 20,
        textAlign: 'center',
        fontSize: '1.8em',
        color: 'red'
    }
}

const Home = props => {
    let history = useHistory();
    const [ allScholars, setAllScholars ] = useState([]);
    const [ scholar, setScholar ] = useState(null);
    const [ allTimeslots , setAllTimeslots ] = useState([]);
    const [ formSubmited, setFormSubmited ] = useState(false);
    const [ id, setId ] = useState(null);

    useEffect(() => {
        async function getAllScholars() {
            try {
                const all = await getScholars();
                // console.log('----all:', all.data?.scholars);
                setAllScholars(all.data?.scholars);
            } catch (e) {
                if (e?.response?.status === 401) {
                    history.push('/login');
                }
            }

            checkIfUserAlreadyLoggedIn({props, history, failedRedirect: '/login'});
        }
        getAllScholars();

        if (props.location?.state?.admin) {
            window.sessionStorage.setItem('admin', props.location.state.admin);
        }
        if (props.location?.state?.id && props.location?.state?.id !== '0') {
            window.sessionStorage.setItem('id', props.location.state.id);
            setId(props.location.state.id);
        }
    }, []);

    useEffect(() => {
        const id = window.sessionStorage.getItem('id');

        if (id && allScholars.length > 0) {
            const found = allScholars.find(sch => sch.id === id);
            if (found) {
                setScholar(found);
            }
        }

    }, [allScholars])

    const updateScholar = id => {
        const found = allScholars.find(theScholar => theScholar.id === id);
        setScholar(found);
    }

    const submitForm = async (selectedTimeslots, selectedSubjects, allTimeslots) => {
        const response = await updateScholarTimeslot(scholar.id, selectedTimeslots, selectedSubjects);

        setAllTimeslots(allTimeslots);
        if (response?.data?.scholar) {
            const newScholar = response?.data?.scholar;
            setScholar(newScholar);

            const theScholarIndex = allScholars.findIndex(scholar => scholar.id === newScholar.id);
            allScholars[theScholarIndex] = newScholar;

            setFormSubmited(true);
        }

        if (response?.data?.scholar?.timeslot) {
            if (response.data.scholar.timeslot.length < selectedTimeslots.length) {
                message.warning({
                    content: 'ONLY PARTIAL timeslot saved',
                    duration: 8,
                    style: { fontSize: '1.5em', backgroundColor: 'red' }
                });
            }
        }
    }

    const resetAll = _ => {
        setScholar(null);
        setFormSubmited(false);
        window.sessionStorage.removeItem('id');
    }

    const redirectToAdmin = _ => {
        history.push('/admin');
    }

    return (
        <div style={styles.home}>
            {
                allScholars && !scholar &&
                    <Scholars 
                        allScholars={allScholars}
                        updateScholar={updateScholar}
                    />
            }
            {
                allScholars && scholar && (
                    <ShowSelectedScholar
                        cname={scholar.cname}
                        ename={scholar.ename}
                        resetAll={resetAll}
                        noClear={id ? true : false}
                    />
                )
            }
            {
                !formSubmited && scholar && scholar.subject.length > 0 && (
                    <DateTime 
                        subjects={scholar.subject}
                        oldTimeslots={scholar.timeslot}
                        submitForm={submitForm}
                        scholar={scholar}
                    />
                )
            }
            {
                formSubmited && allTimeslots && scholar && (
                    <div style={{width: '100%'}}>
                        <OneScholar 
                            allTimeslots={allTimeslots}
                            scholar={scholar}
                            logout={logout}
                            widthStyle='100%'
                        />
                        <OneScholarTimeslot 
                            scholar={scholar}
                        />

                        <hr />

                        <div style={styles.notes}>
                            请牢记您的考试时间段，在考试前5分钟上线准备。
                        </div>
                        <div style={styles.logout}>
                            <Logout />
                        </div>
                    </div>
                )
            }

            {
                window.sessionStorage.getItem('admin') && (
                    <div style={styles.settings} onClick={redirectToAdmin}>
                        <SettingOutlined />
                    </div>
                )
            }

            <div style={styles.exit}>
                <LogoutOutlined onClick={logout.bind(this, history)} />
            </div>
            
        </div>
    )
}

export default Home;