import React, { useState, useEffect } from 'react';

import '../css/hr.css';



const OneScholar = props => {
    const [ subjectsMap, setSubjectsMap ] = useState(null);
    
    const styles = {
        line: {
            width: '100%'
        },
        title: {
            textAlign: 'center'
        },
        subjectsMap: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            padding: 10,
            width: props.widthStyle? props.widthStyle : '30%'
        },
        parentSubjects: {
            width: '20%',
            textAlign: 'right',
            fontWeight: 'bold'
        },
        childSubjects: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginLeft: 40,
            width: '70%'
        },
        childName: {
            marginLeft: 20
        }
    }

    useEffect(() => {
        if (props.scholar) {
            const allSubjects = props.scholar.subject;

            const map = {};
            allSubjects.forEach(sub => {
                if (!map[sub.parent]) {
                    map[sub.parent] = [];
                }
                map[sub.parent].push(sub.name);
            });
            setSubjectsMap(map);
        }
    }, [props.scholar]);

    return (
        <React.Fragment>
            <hr className="line" />

            <div style={styles.title}>Subjects:</div>
            {
                subjectsMap && Object.keys(subjectsMap).map((key, y) => {
                    return (
                        <div key={y} style={styles.subjectsMap}>
                            <div style={styles.parentSubjects}>{key}</div>
                            <div style={styles.childSubjects}>
                                {
                                    subjectsMap[key].length > 0 && subjectsMap[key].map((val, i) => <div key={i} style={styles.childName}>{val}</div>)
                                }
                            </div>
                        </div>
                    )
                })
            }
            
        </React.Fragment>            
    )
}

export default OneScholar;