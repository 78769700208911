import React, { useState, useEffect } from 'react';

import { getSubjects, getScholarById } from '../api/api';

import '../css/hr.css';

const styles = {
    line: {
        width: '100%'
    },
    timeslotMapValue: {
        padding: 5,
        marginLeft: 30,
        textAlign: 'center'
    },
    timeslot: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    theDate: {
        color: '#ff00ff'
    }
}

const OneScholarTimeslot = props => {
    const [ timeslotMap, setTimeslotMap ] = useState(null);
    const [ allSubjects, setAllSubjects ] = useState(null);
    
    useEffect(() => {
        async function getAllSubjects() {
            const all = await getSubjects();
            setAllSubjects(all.data?.subjects);
        }

        getAllSubjects();
    }, []);

    useEffect(() => {
        if (props.scholar && allSubjects) {
            async function getThisScholar()  {
                const response = await getScholarById(props.scholar.id);
                if (response?.data?.scholar) {
                    buildTimeslot(response.data.scholar); 
                }
            }

            getThisScholar();
        }
    }, [props.scholar, allSubjects]);

    const getSubjectInfo = ids => {
        if (!Array.isArray(ids)) {
            ids = [ids];
        }

        // console.log('-----ids:', ids);

        const result = [];
        for (let j=0; j<ids.length; j++) {
            const id = ids[j];
            for (let i=0; i<allSubjects.length; i++) {
                const sub = allSubjects[i];
                // console.log('----sub:', id, sub);

                if (sub._id === id) {
                    // console.log('====found');
                    result.push(sub);
                    break;
                }
            }
        }

        // console.log('-----result:', result);

        return result;
    }

    const buildTimeslot = scholar => {
        const timeslot = scholar.timeslot;
        const subjectByTimeslot = scholar.subjectByTimeslot;
        const map = {};
        timeslot.forEach((time, i) => {
            if (!map[time.date]) {
                map[time.date] = [];
            }
            const obj = {
                time: time.time,
                subjects: getSubjectInfo(subjectByTimeslot[i])
            };
            map[time.date].push(obj); 
        });

        const ordered = Object.keys(map).sort().reduce(
            (obj, key) => { 
              obj[key] = map[key]; 
              return obj;
            }, 
            {}
        );
          
        setTimeslotMap(ordered);
    }

    return (
        <React.Fragment>
            <hr className="line" />
            <div style={styles.timeslot}>
                {
                    allSubjects && timeslotMap && Object.keys(timeslotMap).map((key, y) => {
                        return (
                            <div key={y} style={styles.timeslotMapValue}>
                                <div style={styles.theDate}>{key}</div>
                                {
                                    timeslotMap[key].length > 0 && timeslotMap[key].map((val, i) => {
                                        return (
                                            <div>
                                                <div>{val.time}</div>
                                                {
                                                    val.subjects.map((sub, j) => {
                                                        return (
                                                            <div>{sub.parent} - {sub.name}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        </React.Fragment>            
    )
}

export default OneScholarTimeslot;