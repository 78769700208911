import React, { useState, useEffect } from 'react';
import { Checkbox,  Button, message } from 'antd';

import { getSubjectsRollup } from '../api/api';

const styles = {
    showAllSubjects: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%'
    },
    allSubjects: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 20
    },
    parentSubjects: {
        width: '20%',
        textAlign: 'right',
        fontWeight: 'bold'
    },
    childSubjects: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft: 40,
        width: '70%'
    },
    childName: {
        marginLeft: 20
    }
}

const ShowAllSubjects = props => {
    const [ allSubjects, setAllSubjects ] = useState(null);
    const [ selectedSubjects, setSelectedSubjects ] = useState(null);
    const [ scholar, setScholar ] = useState(null);

    useEffect(() => {
        async function retrieveSubjects() {
            const response = await getSubjectsRollup();
            if (response.data?.subjects) {
                const subjects = response.data.subjects;
                setAllSubjects(subjects);
            }
        }
        retrieveSubjects();
    }, []);

    useEffect(() => {
        if (props.scholar) {
            setScholar(props.scholar);
            const scholarSubjects = props.scholar.subject.map(sub => sub._id);
            setSelectedSubjects(scholarSubjects);
        }
    }, [props.scholar]);

    const onChange = (checkedValues) => {
        setSelectedSubjects(checkedValues);
    }

    const submitForm = _ => {
        if (selectedSubjects && selectedSubjects.length < 1) {
            message.warning({
                content: 'You didn\'t pick anything, are you sure?', 
                style: { fontSize: '1.5em', backgroundColor: 'lightblue' },
                duration: 8
            });
            return;
        }
        props.submitAssignScholarForm(selectedSubjects);
    }
    return (
        <React.Fragment>
            {
                selectedSubjects && (
                    <Checkbox.Group 
                        style={styles.timeslotMap} 
                        onChange={onChange}
                        defaultValue={selectedSubjects}
                    >
                        <div style={styles.showAllSubjects}>
                            <hr className="line" />
                            {
                                allSubjects && Object.keys(allSubjects).map((key, i) => {
                                    return (
                                        <div key={i} style={styles.allSubjects}>
                                            <div style={styles.parentSubjects} key={i}>{key}</div>
                                            <div style={styles.childSubjects}>
                                                {
                                                    allSubjects[key].map((val, y) => {
                                                        return (
                                                            <Checkbox 
                                                                key={y}
                                                                style={styles.childName}
                                                                value={val.id}>
                                                                {val.name}
                                                            </Checkbox>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div>
                                <Button 
                                    type="primary" 
                                    onClick={submitForm}
                                >Submit</Button>
                            </div>
                        </div>
                    </Checkbox.Group>
                )
            }
        </React.Fragment>
    )
}

export default ShowAllSubjects;