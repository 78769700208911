import React, { useState, useEffect } from 'react';
import { Modal, Select, Radio, Space } from 'antd';

import { getTimeslotsRollup, getTeachersRollup, updateScholar } from '../../api/api';
import { isEmptyObject } from '../../utils/util';

const { Option } = Select;

const styles = {
    timeslot: {
        textAlign: 'center',
        width: '100%'
    },
    timeslotAdmin: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    timeslotTable: {
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'center',
        padding: 5,
        borderBottom: '1px solid lightgray',
        width: '100%'
    },
    pad: {
        flex: 1
    },
    date: {
        flex: 2,
        textAlign: 'right',
        color: 'purple',
        padding: 5
    },
    content: {
        flex: 8,
        textAlign: 'left',
        marginLeft: 20
    },
    timetable: {
        display: 'flex',
        flexDirection: 'row'
    },
    timetableContent: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        padding: 10
    },
    timetableTime: {
        flex: 1,
        textAlign: 'right'
    },
    timetableScholar: {
        flex: 10,
        textAlign: 'left',
        marginLeft: 20,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    scholarName: {
        marginRight: 40,
        padding: 10
    },
    theName: {
        fontWeight: 'bold'
    },
    subjectList: {
        paddingLeft: 5,
        color: 'purple'
    },
    teacherName: {
        color: 'gray',
        backgroundColor: '#aef9ae'
    },
    clearButton: {
        marginLeft: 20
    },
    selectTeacher: {
        width: '100%',
        textAlign: 'center',
        padding: 10
    }
}

const TimeslotAdminPage = props => {
    const [ timeslots, setTimeslots ] = useState(null);
    const [ timeslot2TeacherMap, setTimeslot2TeacherMap ] = useState([]);
    const [ allTeachers, setAllTeachers ] = useState(null);
    const [ showModal, setShowModal ] = useState(false);
    const [ modalContent, setModalContent ] = useState(null);
    const [ modalTitle, setModalTitle ] = useState('-');
    const [ scholarMap, setScholarMap ] = useState(null);

    const [ selectedScholarId, setSelectedScholarId ] = useState(null);
    const [ selectedTimeslotIndex, setSelectedTimeslotIndex ] = useState(null);
    const [ selectedTeacher, setSelectedTeacher ] = useState(null);
    const [ selectedDate, setSelectedDate ] = useState(null);
    const [ timeslotId, setTimeslotId ] = useState(null);
    const [ selectedTime, setSelectedTime ] = useState(null);
    const [ showOneTeacher, setShowOneTeacher ] = useState(null);

    async function getAllTimeslots() {
        const response = await getTimeslotsRollup();
        if (response?.data?.timeslots) {
            const timeslots = response.data.timeslots;
            setTimeslots(timeslots);
        }
    }

    async function getAllTeachers() {
        const response = await getTeachersRollup();
        if (response?.data?.teachers) {
            const teachers = response.data.teachers;
            setAllTeachers(teachers);
        }
    }

    const onSelectTeacher = value => {
        console.log(`selected ${value}`);
        if (value === '00') {
            setShowOneTeacher(null);
        } else {
            setShowOneTeacher(value);
        }
    }

    const initTimeslot2Teacher = _ => {
        // console.log('-----timeslots:', timeslots);
        // console.log('-----allTeachers:', allTeachers);

        const map = {};
        const scholarMap = {};

        timeslots && Object.keys(timeslots).forEach(key => {
            const times = timeslots[key];
            times.forEach(time => {
                const timeId = time.id;
                const teachers = time.teacher;
                const scholars = time.scholar;

                if (!map[timeId]) {
                    map[timeId] = [];
                }
                teachers && teachers.forEach(teacher => {
                    if (!isEmptyObject(teacher)) {
                        map[timeId].push({
                            id: teacher._id,
                            name: teacher.name //getTeacherName(teacher)
                        })
                    } else {
                        map[timeId].push({});
                    }
                });

                scholars && scholars.forEach(scholar => {
                    scholarMap[scholar.id] = scholar;
                })
            })
        });
        setTimeslot2TeacherMap(map);
        setScholarMap(scholarMap);
    }

    useEffect(() => {
        initTimeslot2Teacher();
    }, [timeslots, allTeachers]);

    useEffect(() => {
        getAllTimeslots();
        getAllTeachers();
    }, []);

    useEffect(() => {
        getAllTimeslots();
    }, [props.reset]);

    const onChange = (event) => {
        // console.log(`selected ${event.target.value} ${selectedScholarId} ${selectedTimeslotIndex}`);
        setSelectedTeacher(event.target.value);
    }

    const handleAddTeacher = (scholarIndex, scholarId, oneTime, date) => {
        // console.log('----i:', scholarIndex, scholarId, oneTime.id, timeslot2TeacherMap);
        setSelectedScholarId(scholarId);
        setSelectedTimeslotIndex(scholarIndex);
        // console.log('-------oneTime id:', oneTime.id)
        setTimeslotId(oneTime.id);
        setSelectedTime(oneTime);
        setSelectedDate(date);

        const selectedTeachers = timeslot2TeacherMap[oneTime.id];
        const selected = [];
        const availableTeachers = [];
        selectedTeachers.forEach(teach => {
            if (teach.id) {
                selected.push(teach.id);
            }
        });
        Object.keys(allTeachers).forEach(key => {
            if (selected.indexOf(key) < 0 || allTeachers[key] === '---') {
                availableTeachers[key] = allTeachers[key];
            }
        })

        let selectTeacher = (
            <Radio.Group 
                onChange={onChange}
            >
                <Space direction="vertical">
                    {
                        availableTeachers && Object.keys(availableTeachers).map((key, i) => {
                            return (
                                <Radio key={i} value={key}>{availableTeachers[key]}</Radio>
                            )
                        })
                    }
                </Space>
            </Radio.Group>
        );

        if (oneTime.teacher && oneTime.teacher.length > 0 && !isEmptyObject(oneTime.teacher[scholarIndex])) {
            // teacher already assigned
            setModalTitle(
            <div>Selected Teacher: {oneTime.teacher[scholarIndex].name}</div>
            );
        } else {
            // teacher not assinged yet
            setModalTitle('Please select a teacher:');
        }

        setModalContent(selectTeacher);
        setShowModal(true);
    };

    const handleOk = async (thisTimeslotId, theScholarId, thisDate) => {
        // console.log('-----selectedScholarId', selectedScholarId);
        // console.log('-----selectedTeacher', selectedTeacher);
        // console.log('-----selectedTimeslotIndex', selectedTimeslotIndex);
        // console.log('-----timeslotId:', timeslotId);
        // console.log('-----oneTime', oneTime);

        const currentMap = Object.assign({}, timeslot2TeacherMap);
        // console.log('-----currentMap:', currentMap);
        
        const theTimeslotId = timeslotId || thisTimeslotId;

        const currentTeachers = currentMap[theTimeslotId];
        // console.log('-----currentTeachers:', currentTeachers);
        currentTeachers[selectedTimeslotIndex] = selectedTeacher ? selectedTeacher : '';
        currentMap[theTimeslotId] = currentTeachers;
        setTimeslot2TeacherMap(currentMap);

        const theSelectedScholarId = selectedScholarId || theScholarId;

        // update ScholarMap
        const oldScholarMap = Object.assign({}, scholarMap);
        const thisScholar = oldScholarMap[theSelectedScholarId];
        thisScholar.teachers[selectedTimeslotIndex] = selectedTeacher;
        oldScholarMap[theSelectedScholarId] = thisScholar;
        setScholarMap(oldScholarMap);

        const result = await updateScholar(theSelectedScholarId, {teacherByTimeslot: {timeslotId: theTimeslotId, teacher: selectedTeacher}});

        const theSelectedDate = selectedDate || thisDate;

        if (result?.data) {
            // save timeslot
            const oldTimeslots = Object.assign({}, timeslots);
            // console.log('------oldTimeslots:', oldTimeslots);
            // console.log('------selectedDate:', theSelectedDate);
            const times = oldTimeslots[theSelectedDate];
            let theTime;
            let j = 0;
            for (j=0; j<times.length; j++) {
                if (times[j].id === theTimeslotId) {
                    theTime = times[j];
                    break;
                }
            }
            if (theTime) {
                const theScholars = theTime.scholar;
                const theTeacher = theTime.teacher;
                const newScholars = [];

                // console.log('----old theTeacher', theTeacher);

                theScholars.forEach((scholar, index) => {
                    if (scholar.id+'' === theSelectedScholarId+'') {
                        // console.log('----found');
                        scholar.teachers[selectedTimeslotIndex] = selectedTeacher;
                    }
                    newScholars[index] = scholar;
                })
                theTeacher[selectedTimeslotIndex] = {_id: selectedTeacher, name: allTeachers[selectedTeacher]};

                // console.log('----theTeacher', theTeacher);

                theTime.scholar = newScholars;

                theTime.teacher = theTeacher;

                console.log('----j', j);
                oldTimeslots[theSelectedDate][j] = theTime;
                setTimeslots(oldTimeslots);
            
                // console.log('----oldTimeslots', oldTimeslots);
            }
        }

        setModalContent(null);
        setModalTitle('-');
        setShowModal(false);
        setSelectedTeacher(null);
        setTimeslotId(null);
        setSelectedDate(null);
        setSelectedTime(null)
    };
    
    const handleCancel = () => {
        setModalContent(null);
        setModalTitle('-');
        setShowModal(false);
        setSelectedTeacher(null);
        setTimeslotId(null);
        setSelectedDate(null);
        setSelectedTime(null)
    };

    const buildTimeTable = (times, date) => {
        // console.log(times);
        return times.map((oneTime, j) => {
            return (
                <div style={styles.timetableContent}>
                    <div style={styles.timetableTime}>{oneTime.time}</div>
                    <div style={styles.timetableScholar}>
                        {
                            oneTime.scholar && oneTime.scholar.map((scholar, i) => {
                                if (showOneTeacher) {
                                    if (showOneTeacher === '---') {
                                        // show empty
                                        if (isEmptyObject(oneTime.teacher[i]) || oneTime.teacher[i]._id === '60c196bf30c47ccf511b0dee' || oneTime.teacher[i]._id === '60c1a50c75f7ac083463bc23') {

                                        } else {
                                            return null;
                                        }
                                    } else if (showOneTeacher === '00') {
                                        // show all
                                    } else if (showOneTeacher !== oneTime.teacher[i].name) {
                                        return null;
                                    }
                                }
                                
                                let name = scholar.ename;
                                if (scholar.cname) {
                                    name += ' ' + scholar.cname;
                                }
                                let subjects = '';
                                if (scholar.subjects && scholar.subjects.length > 0) {
                                    scholar.subjects.forEach(sub => {
                                        if (!subjects) {
                                            subjects += '(';
                                        } else {
                                            subjects += ', ';
                                        }
                                        subjects += sub.parent + '-' + sub.name;
                                    });
                                    if (subjects) {
                                        subjects += ')';
                                    }
                                }
                                return (
                                    <div 
                                        key={i} 
                                        style={styles.scholarName}
                                        onClick={handleAddTeacher.bind(this, i, scholar.id, oneTime, date)}
                                    >
                                        <span style={styles.theName}>
                                            { name }
                                            { 
                                                (oneTime.teacher && !isEmptyObject(oneTime.teacher[i])) ? (
                                                    <span>(<span style={styles.teacherName}> { oneTime.teacher[i].name } </span>)</span>
                                                ) : <span>(<span style={styles.teacherName}> --- </span>)</span>
                                            }
                                        </span>
                                        { subjects && (
                                            <span style={styles.subjectList}>{subjects}</span>
                                        )}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        })
    }

    return (
        <React.Fragment>
            <div style={styles.selectTeacher}>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select a teacher"
                    optionFilterProp="children"
                    onChange={onSelectTeacher}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option key={100} value='00'>---ALL---</Option>
                    {
                        allTeachers && Object.keys(allTeachers).map((teacherId, i) => {
                            return <Option key={i} value={allTeachers[teacherId]}>{allTeachers[teacherId]}</Option>
                        })
                    }
                </Select>
            </div>
            <table style={styles.timeslot}>
                { 
                    timeslots && Object.keys(timeslots).map((key, i) => {
                        return (
                            <div key={i} style={styles.timeslotTable}>
                                <div style={styles.pad}></div>
                                <div style={styles.date}>
                                    { key }
                                </div>
                                <div style={styles.content}>
                                    { buildTimeTable(timeslots[key], key) }
                                </div>
                                <div style={styles.pad}></div>
                            </div>
                        )
                    })
                }
            </table>
            <Modal 
                title={modalTitle} 
                visible={showModal} 
                onOk={handleOk} 
                onCancel={handleCancel}
                okText="Save"
            >
                { modalContent }
            </Modal>
        </React.Fragment>
    )
}

export default TimeslotAdminPage;