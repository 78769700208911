import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Checkbox, Button, Modal } from 'antd';

import DisplaySubjectMap from './DisplaySubjectMap';
import SelectRemainingSubjects from './SelectRemainingSubjects';
import OneSubject from './OneSubject';

import { getTimeslotsRollup } from '../api/api';

import '../css/hr.css';

const subjectsPerTimelot = 3;
const nDays = 4;

const styles = {
    dateTime: {
        width: '100%',
        padding: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    subjectsMap: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    subjectsMapKey: {
        padding: 5
    },
    subjectsMapValue: {
        padding: 5,
        marginLeft: 30
    },
    subject: {
        paddingLeft: 20,
        paddingRight: 20
    },
    timeslot: {
        paddingLeft: 20,
        paddingRight: 20,
        textAlign: 'center'
    },
    timeslotPickNum: {
        fontSize: '1.2em',
        marginTop: 50,
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    timeslotNum: {
        color: '#ff00ff',
        fontSize: '2.0em',
        marginLeft: 20
    },
    submitButton: {
        marginLeft: 20
    },
    timeslotMap: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    timeslotMapKey: {
        padding: 5
    },
    timeslotMapValue: {
        padding: 5,
        marginLeft: 30
    },
    theDate: {
        color: '#ff00ff'
    },
    notes: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 20,
        border: '1px solid blue',
        borderRadius: 20
    },
    displayTime: {
        display: 'inline-block',
        marginLeft: 5,
        cursor: 'pointer'
    },
    subjectMap: {
        marginTop: 30
    }
}

const DateTime = props => {
    let history = useHistory();
    const [ scholar, setScholar ] = useState(null);
    const [ subjects, setSubjects ] = useState(null);
    const [ numOfSlots, setNumOfSlots ] = useState(0);
    const [ allowedNumOfSlots, setAllowedtNumOfSlots ] = useState(0);
    const [ subjectsMap, setSubjectsMap ] = useState(null);
    const [ timeslots, setTimeslots ] = useState(null);
    const [ selectedTimeslots, setSelectedTimeslots ] = useState(null);
    const [ disabled, setDisabled ] = useState(false);
    const [ allReady, setAllReady ] = useState(false);

    const [ selectableSubjects, setSelectableSubjects ] = useState(null);   // the remaining selectable subjects
    const [ lastOne, setLastOne ] = useState(null);                         // this is the last checked checkbox
    const [ selectedSubjects, setSelectedSubjects ] = useState({});         // the index corresponds to the order of selectedTimeslots
    const [ , setAddingTimeslot ] = useState(false);

    const [ showOneSubject, setShowOneSubject ] = useState(false);
    const [ clickTime, setClickTime ] = useState(null);
    const [ clickSubjects, setClickSubjects ] = useState(null);

    useEffect(() => {
        if (props.scholar) {
            setScholar(props.scholar);

            const timeslot = props.scholar.timeslot;
            const subjectByTimeslot = props.scholar.subjectByTimeslot;
            const subject = props.scholar.subject;

            const already = {};
            timeslot.forEach((time, i) => {
                already[time._id] = subjectByTimeslot[i];
            })
            setSelectedSubjects(already);
            // console.log('--------already:', already);

            const allSubjects = subjectByTimeslot.flat();
            // console.log('--------allSubjects:', allSubjects);
            const remaining = [];
            for (let i=0; i<subject.length; i++) {
                const sub = subject[i];
                // console.log('----sub._id', sub._id);
                // console.log('----notfound', allSubjects.indexOf(sub._id));
                if (allSubjects.indexOf(sub._id) < 0) {
                    // console.log('----adding')
                    remaining.push(sub);
                }
            }
            // console.log('--------remaining:', remaining);
            setSelectableSubjects(remaining);

        }
    }, [props.scholar]);

    useEffect(() => {
        if (props.oldTimeslots) {
            // console.log('----------props.oldTimeslots', props.oldTimeslots);
            if (props.oldTimeslots.length > 0) {
                const times = props.oldTimeslots.map(old => old._id);
                setSelectedTimeslots(times);
            } else {
                setSelectedTimeslots([]);
            }
        }
    }, [props.oldTimeslots]);

    useEffect(() => {
        if (props.subjects) {
            // console.log('----------props.subjects');
            const allSubjects = props.subjects;
            setSubjects(allSubjects);

            const num = Math.ceil(allSubjects.length / subjectsPerTimelot);

            setNumOfSlots(num);
            setAllowedtNumOfSlots(num);

            const map = {};
            allSubjects.forEach(sub => {
                if (!map[sub.parent]) {
                    map[sub.parent] = [];
                }
                map[sub.parent].push(sub.name);
            });
            setSubjectsMap(map);
        }
    }, [props.subjects]);

    useEffect(() => {
        async function getDateTimes() {
            try {
                const times = await getTimeslotsRollup();
                const allTimeslots = times?.data?.timeslots;
                if (!allTimeslots) {
                    return;
                }
                setTimeslots(allTimeslots);

                // console.log('-----allTimeslots:', allTimeslots);

                setTimeout(async () => {
                    validateTimeslots();
                    setAllReady(true);
                }, 500);
            } catch (e) {
                history.push('/login');
            }
        }

        if (subjects && selectedTimeslots) {
            // console.log('-------', subjects, selectedTimeslots);
            getDateTimes();
        }
    }, [subjects, selectedTimeslots]);

    const validateTimeslots = (theTimeslots, num) => {
        const numLeft = allowedNumOfSlots - selectedTimeslots.length;
        setNumOfSlots(numLeft);
        if (numLeft < 1) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }

    const onChange = (checkedValues) => {
        // console.log('checked = ', checkedValues);
        const oldSelectedTimeslot = [...selectedTimeslots];
        const oldSelectedTimeslotLength = selectedTimeslots.length;
        const newSelectedTimeslotLength = checkedValues.length;

        const addingTimeslot = newSelectedTimeslotLength > oldSelectedTimeslotLength;

        setSelectedTimeslots(checkedValues);
        setAddingTimeslot(addingTimeslot);

        // console.log('-------selectable:', selectableSubjects);

        if (addingTimeslot) {
            // get the last one and prompt to pick subject for this one.
            const last = checkedValues[checkedValues.length -1];
            setLastOne(last);
        } else {
            // remove the removed ones from the selectedSubjects
            // console.log('====oldSelectedTimeslot', oldSelectedTimeslot);
            let removed;
            for (let i=0; i<oldSelectedTimeslot.length; i++) {
                const sel = oldSelectedTimeslot[i];
                if (checkedValues.indexOf(sel) < 0) {
                    removed = sel;
                }
            }

            // console.log('====removed', removed);
            // console.log('====selectedSubjects', selectedSubjects);
            // console.log('---addback:', selectedSubjects[removed]);

            if (removed) {
                const selected = Object.assign({}, selectedSubjects);
                delete selected[removed];
                setSelectedSubjects(selected);
                // console.log('====selected', selected);

                const addBack = selectedSubjects[removed];
                const selectable = [...selectableSubjects];
                addBack.forEach(add => {
                    scholar.subject.forEach(sub => {
                        if (sub._id === add) {
                            selectable.push(sub);
                        }
                    })
                });
                setSelectableSubjects(selectable);
            }
        }

        setTimeout(() => {
            validateTimeslots();
        }, 300);
    }

    const inOldTimeslot = id => {
        if (props.oldTimeslots && props.oldTimeslots.length > 0) {
            for (let i=0; i<props.oldTimeslots.length; i++) {
                const old = props.oldTimeslots[i];
                if (old._id === id) {
                    return true;
                }
            }
        }
        return false;
    }

    const lessThanNDays = (first) => {
        if (window.sessionStorage.getItem('admin') === 'true' || window.sessionStorage.getItem('admin') === true) {
            return false;
        }

        const now = new Date();
        const one = new Date(first+'T00:00:00');
        const two = new Date(now.getFullYear(), now.getMonth(), now.getDate());

        // Do the math.
        const millisecondsPerDay = nDays * 1000 * 60 * 60 * 24;
        const millisBetween = Math.abs(two.getTime() - one.getTime());
        const result = millisBetween < millisecondsPerDay;

        console.log('------one----:', one);
        console.log('------two----:', two);
        console.log('------result----:', result);

        return result;
    }

    const isDisabled = (val, key) => {
        // console.log('----val:', key, val);
        if (lessThanNDays(key)) {
            return true;
        }
        if (selectedTimeslots.indexOf(val.id) > -1) {
            return false;
        } else {
            if (inOldTimeslot(val.id)) {
                return false;
            }
            if (!val.enabled) {
                return true;
            }
        }
        return disabled;
    }

    const submitForm = _ => {
        // console.log('-----selectedTimeslots:', selectedTimeslots);
        // console.log('-----selectedSubjects:', selectedSubjects);

        const times = [];
        const subjects = [];
        const keys = Object.keys(selectedSubjects);
        for (let i=0; i<keys.length; i++) {
            const key = keys[i];
            const vals = selectedSubjects[key];
            times.push(key);
            subjects.push(vals);
        }
        
        props.submitForm(times, subjects, timeslots);
    }

    const findTimeslotInfo = id => {
        const keys = Object.keys(timeslots);
        for (let i=0; i<keys.length; i++) {
            const values = timeslots[keys[i]];
            for (let j=0; j<values.length; j++) {
                if (values[j].id === id) {
                    const val = Object.assign({}, values[j]);
                    val.date = keys[i];
                    return val;
                }
            }
        }
        return {};
    }

    const submitTimeslotSubjects = subjects => {
        if (!subjects) {
            // handle cancel
            // console.log('-----old selectedTimeslots:', selectedTimeslots);
            const removed = lastOne;
            // console.log('-----removed:', removed);
            const selected = [...selectedTimeslots];
            // console.log('-----selected:', selected);
            const index = selected.findIndex(sel => sel === removed);
            // console.log('-----index:', index);

            if (index > -1) {
                selected.splice(index, 1);
            }
            
            setSelectedTimeslots(selected);
            setAllReady(false);

            setTimeout(() => {
                setAllReady(true);
            }, 200);

            // console.log('-----selectedSubjects:', selectedSubjects);
            
            setLastOne(null);
            setAddingTimeslot(false);
        } else {
            // selected subjects for a timeslot
            const selected = Object.assign({}, selectedSubjects);
            const key = lastOne;
            selected[key] = subjects;
            setSelectedSubjects(selected);

            const remaining = [];
            const selectable = [...selectableSubjects];
            selectable.forEach(subject => {
                if (subjects.indexOf(subject._id) < 0) {
                    remaining.push(subject);
                }
            });
            setSelectableSubjects(remaining);

            // console.log('-----selected:', selected);

            setLastOne(null);
            setAddingTimeslot(false);
        }
    }

    const showModalForTime = async (id) => {
        const timeslot = scholar.timeslot;
        const timeIndex = timeslot.findIndex(slot => {
            return slot._id === id;
        });

        if (timeIndex < 0) {
            return;
        }
        const subjects = scholar.subject;
        const subjectIds = scholar.subjectByTimeslot[timeIndex];

        const clickSubjects = [];
        await subjectIds.forEach(async (id, i) => {
            const subj = await subjects.find(sub => {
                return sub._id === id;
            });
            clickSubjects.push(
                <div key={i}>{subj.parent} - {subj.name}</div>
            )
        })

        setClickSubjects(clickSubjects);
        setClickTime(timeslot[timeIndex].time);
        setShowOneSubject(true);
    }

    const handleCloseOneSubject = _ => {
        setShowOneSubject(false);
    }

    return (
        <React.Fragment>
            <hr className="line" />
            <div style={styles.dateTime}>
                <div style={styles.timeslot}>
                    { allReady ? (
                            <Checkbox.Group 
                                style={styles.timeslotMap} 
                                onChange={onChange}
                                defaultValue={selectedTimeslots}
                            >
                            {
                                timeslots && Object.keys(timeslots).map((key, y) => {
                                    return (
                                        <div key={y} style={styles.timeslotMapValue}>
                                            <span style={styles.theDate}>{key}</span>
                                            {
                                                timeslots[key].length > 0 && timeslots[key].map((val, i) => {
                                                    return (
                                                        <div>
                                                            <Checkbox 
                                                                key={i}
                                                                value={val.id} 
                                                                disabled={isDisabled(val, key)}>
                                                            </Checkbox>
                                                            <div 
                                                                style={styles.displayTime}
                                                                onClick={showModalForTime.bind(this, val.id)}
                                                            >
                                                                {val.time}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                            <Modal
                                                title="Please select 3 subjects for this timeslot"
                                                visible={showOneSubject}
                                                onOk={handleCloseOneSubject}
                                                onCancel={handleCloseOneSubject}
                                            >
                                                <OneSubject 
                                                    time={clickTime}
                                                    subjects={clickSubjects}
                                                />
                                            </Modal>
                                        </div>
                                    )
                                })
                            }
                            </Checkbox.Group>
                    ) : null }  
                </div>
                <div style={styles.timeslotPickNum}>
                    <div>Remaining Selection: </div>
                    <div style={styles.timeslotNum}>{ numOfSlots }</div>
                    <div style={styles.submitButton}>
                        <Button 
                            type="primary" 
                            onClick={submitForm}
                        >Submit</Button>
                    </div>
                </div>

                {
                    lastOne ? 
                        <SelectRemainingSubjects 
                            selectableSubjects={selectableSubjects} 
                            show={lastOne !== null}
                            findTimeslotInfo={findTimeslotInfo}
                            timeslot={lastOne}
                            submitTimeslotSubjects={submitTimeslotSubjects}
                        /> : null
                }
            </div>

            <div style={styles.notes}>
                <div>
                    <div>温馨提示：</div>
                    <ol>
                        <li>我们的考试时间有限，请大家选择适合的时间并尽快提交。</li>
                        <li>每个考试时间段是15分钟。如果希望连续考试，请选择连续的时间段。</li>
                        <li>请大家根据自己的考试时间来安排合适的复习计划。</li>
                        <li>考试前两天内不可以更改考试时间。如果有特殊情况请联系林老师：415-335-2472 或者吴老师 510-600-5858.</li>
                        <li>祝每一位考生都能够顺利通过考试！</li>
                    </ol>
                </div>
            </div>
            <div style={styles.subjectMap}>
                <DisplaySubjectMap subjectsMap={subjectsMap} />
            </div>
                
        </React.Fragment>
    )
}

export default DateTime;