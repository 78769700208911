const axios = require('axios');

const LOGIN = '/login';
const TIMESLOTS = '/timeslots';
const SCHOLARS = '/scholars';
const SUBJECTS = '/subjects';
const MENU = '/menu';
const VERIFY = '/verify';
const TEACHER = '/teachers';

axios.interceptors.request.use((config) => {
    config.headers = {
        token: window.localStorage.getItem('token')
    };
    return config;
});

const getUrl = _ => {
    let url = 'https://zyapi.fycd.us';
    if (process.env.NODE_ENV !== 'production') {
        url = 'http://localhost:4011';
    }
    
    return url;
}

const getAdminMenu = async () => {
    return await axios({
        method: 'get',
        url: getUrl()+MENU
    });
}

const getTimeslots = async (params) => {
    return await axios({
        method: 'get',
        url: getUrl()+TIMESLOTS,
        params
    });
}

const getTimeslotsRollup = () => getTimeslots({rollup: 1});

const createTimeslot = async (data) => {
    return await axios({
        method: 'post',
        url: getUrl()+TIMESLOTS,
        data
    });
}

const getSubjects = async (params) => {
    return await axios({
        method: 'get',
        url: getUrl()+SUBJECTS,
        params
    });
}

const getSubjectsRollup = () => getSubjects({rollup: 1});

const getScholars = async (params) => {
    return await axios({
        method: 'get',
        url: getUrl()+SCHOLARS,
        params
    });
}

const getScholarsRollup = () => getScholars({rollup: 1});

const getScholarById = async (id) => {
    return await axios({
        method: 'get',
        url: getUrl()+SCHOLARS+'/'+id
    });
}

const loginUser = async (data) => {
    return await axios({
        method: 'post',
        url: getUrl()+LOGIN,
        data
    });
}

const createScholar = async (data) => {
    return await axios({
        method: 'post',
        url: getUrl()+SCHOLARS,
        data
    });
}

const updateScholar = async (id, data) => {
    return await axios({
        method: 'patch',
        url: getUrl()+SCHOLARS+'/'+id,
        data
    });
}

const verifyUser = async () => {
    return await axios({
        method: 'get',
        url: getUrl()+VERIFY
    });
}

const getTeachers = async (params) => {
    return await axios({
        method: 'get',
        url: getUrl()+TEACHER,
        params
    });
}

const getTeachersRollup = () => getTeachers({rollup: 1});

const createTeacher = async (data) => {
    return await axios({
        method: 'post',
        url: getUrl()+TEACHER,
        data
    });
}



export {
    getUrl,

    getAdminMenu,
    
    getTimeslots,
    getTimeslotsRollup,
    createTimeslot,

    getSubjects,
    getSubjectsRollup,

    loginUser,
    verifyUser,

    getScholars,
    getScholarsRollup,
    getScholarById,
    createScholar,
    updateScholar,

    getTeachers,
    getTeachersRollup,
    createTeacher
};