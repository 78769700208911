import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message } from 'antd';

import { createTeacher } from '../../api/api';

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
};

const styles = {
    title: {
        textAlign: 'center',
        padding: 40,
        fontSize: '1.5em'
    }
}

const AddTeacherAdminPage = props => {
    const [form] = Form.useForm();

    const onReset = () => {
        form.resetFields();
    };

    const onFinish = async (values) => {
        console.log('Success:', values);

        try {
            const response = await createTeacher(values);
            console.log('---', response);

            if (response?.data) {
                if (response.data.added === 'true' || response.data.added === true) {
                    message.success({
                        content: 'Added', 
                        style: { fontSize: '1.5em', backgroundColor: 'lightblue' },
                        duration: 5
                    });
                    onReset();
                } else {
                    message.error({
                        content: 'Something went wrong. Please check with Admin!', 
                        style: { fontSize: '1.5em', backgroundColor: 'red' },
                        duration: 5
                    });
                }
            }
        } catch (error) {
            if (error?.response?.data) {
                message.error({
                    content: error.response.data.message, 
                    style: { fontSize: '1.5em', backgroundColor: 'red' },
                    duration: 5
                });
            }
        }
    };

    return (
        <div>
            <div style={styles.title}>Add Teacher</div>

            <Form
                {...layout}
                name="basic"
                initialValues={{
                    remember: true,
                }}
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: "Please enter teacher's name" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">Add</Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default AddTeacherAdminPage;