import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Login from './Login';
import Home from './Home';
import AdminPage from './AdminPage';

const ScholarApp = props => {
    return (
        <Router>
            <Switch>
                <Route path="/login" render={(props) => <Login {...props} />} />
                <Route path="/home" render={props => <Home {...props} />} />
                <Route path="/admin" render={props => <AdminPage {...props} />} />
                <Route path="*" render={(props) => <Login {...props} />} />
            </Switch>
        </Router>
    )
}

export default ScholarApp;