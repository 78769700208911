import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Tabs } from 'antd';
import { HomeOutlined, LogoutOutlined } from '@ant-design/icons';

import AssignSubject from './admin/AssignSubject';
import ScholarAdminPage from './admin/ScholarAdminPage';
import TimeslotAdminPage from './admin/TimeslotAdminPage';
import AddTimeslotAdminPage from './admin/AddTimeslotAdminPage';
import AddTeacherAdminPage from './admin/AddTeacherAdminPage';

import { logout } from '../utils/util';

const { TabPane } = Tabs;

const styles = {
    admin: {
        padding: 30,
        width: '100%',
        marginTop: 40
    },
    assignSubject: {
         textAlign: 'center'
    },
    homeButton: {
        position: 'absolute',
        top: 20,
        left: 20,
        fontSize: '2.0em',
        cursor: 'pointer'
    },
    exit: {
        position: 'absolute',
        top: 20,
        right: 20,
        fontSize: '2.0em',
        cursor: 'pointer'
    }
}

const AdminPage = props => {
    const history = useHistory();
    const [ key, setKey ] = useState(1);

    const backToHome = _ => {
        history.push('/home');
    }

    const handleTabClick = key => {
        console.log('===', key)
        setKey(key);
    }

    return (
        <div style={styles.admin}>
            <Tabs type="card" defaultActiveKey="1" onTabClick={handleTabClick}>
                <TabPane tab="Assign Subject" key="1">
                    <AssignSubject />
                </TabPane>
                <TabPane tab="Scholars" key="5">
                    <ScholarAdminPage />
                </TabPane>
                <TabPane tab="Timeslots" key="4" forceRender={true}>
                    <TimeslotAdminPage
                        reset={key === '4'}
                    />
                </TabPane>
                <TabPane tab="Add Timeslot" key="2">
                    <AddTimeslotAdminPage />
                </TabPane>
                <TabPane tab="Add Teacher" key="3">
                    <AddTeacherAdminPage />
                </TabPane>
                <TabPane tab="Add Scholar" key="6">
                    
                </TabPane>
            </Tabs>

            <div style={styles.homeButton}>
                <HomeOutlined onClick={ backToHome } />
            </div>

            <div style={styles.exit}>
                <LogoutOutlined onClick={logout.bind(this, history)} />
            </div>
        </div>
    )
}

export default AdminPage;