import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const Scholars = props => {
    if (props.allScholars) {
        function onChange(value) {
            props.updateScholar(value);
        }

        return (
            <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select a person"
                optionFilterProp="children"
                onChange={onChange}
                
                filterOption={(input, option) =>
                    option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {
                    props.allScholars.map(scholar => {
                        return (
                            <Option key={scholar.id} value={scholar.id}>{scholar.cname} {scholar.ename}</Option>
                        )
                    })
                }
            </Select>
        )
    } else {
        return null;
    }
}

export default Scholars;