import { verifyUser, updateScholar } from '../api/api';

const checkIfUserAlreadyLoggedIn = ({props, history, failedRedirect, successRedirect}) => {
    if (!props.location?.state?.username) {
        // verify if user has logged-in
        // if not, redirect to failedRedirect
        // if logged-in, redirect to successRedirect
        async function fetchData() {
            const verified = await verifyUser();
            if (successRedirect && verified.data?.verify?.username) {
                history.push(successRedirect);
            } 
            if (failedRedirect && !verified.data?.verify?.username) {
                history.push(failedRedirect);
            }
        }
        fetchData();
    }
}

const checkIfUserIsAdmin = ({props, history, failedRedirect, successRedirect}) => {
    return new Promise((resolve, reject) => {
    if (!props.location?.state?.username) {
        // verify if user has logged-in
        // if not, redirect to failedRedirect
        // if logged-in, redirect to successRedirect
        async function fetchData() {
            const verified = await verifyUser();
            // console.log('====ver', verified.data);
            if (successRedirect && verified.data?.verify?.admin) {
                history.push(successRedirect);
                reject();
            } 
            if (failedRedirect && !verified.data?.verify?.admin) {
                history.push(failedRedirect);
                reject();
            }
            resolve()
        }
        fetchData();
    }
    })
}

const updateScholarTimeslot = async(id, selectedTimeslots, selectedSubjects) => {
    const response = await updateScholar(id, {timeslot: selectedTimeslots, subjectByTimeslot: selectedSubjects});
    return response;
}

const logout = history => {
    window.localStorage.removeItem('token');
    window.sessionStorage.removeItem('admin');
    window.sessionStorage.removeItem('id');
    history.push('/');
}

const isEmptyObject = obj => {
    for(var prop in obj) {
      if(obj.hasOwnProperty(prop)) {
        return false;
      }
    }
  
    return JSON.stringify(obj) === JSON.stringify({});
  }

export {
    checkIfUserAlreadyLoggedIn,
    checkIfUserIsAdmin,
    updateScholarTimeslot,

    isEmptyObject,

    logout
}