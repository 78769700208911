import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, message } from 'antd';

import Scholars from '../Scholars';
import ShowSelectedScholar from '../ShowSelectedScholar';
import ShowAllSubjects from '../ShowAllSubjects';
import OneScholar from '../OneScholar';

import { getSubjectsRollup, getScholars, updateScholar } from '../../api/api';
import { checkIfUserIsAdmin } from '../../utils/util';

const styles = {
    assignSubject: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center'
    }
}

const AssignSubject = props => {
    const history = useHistory();
    const [ allSubjects, setAllSubjects ] = useState(null);
    const [ allScholars, setAllScholars ] = useState(null);
    const [ selectedScholar, setSelectedScholar ] = useState(null);
    const [ formSubmitted, setFormSubmitted ] = useState(false);

    useEffect(() => {
        async function loadSubjects() {
            try {
                await checkIfUserIsAdmin({props, history, failedRedirect: '/home'});

                const response = await getSubjectsRollup();

                if (response.data?.subjects) {
                    setAllSubjects(response.data.subjects);
                }
            } catch (e) {
                // console.log('-----e:', e);
            }
        }

        async function getAllScholars() {
            const all = await getScholars();
            // console.log('----all:', all.data?.scholars);
            setAllScholars(all.data?.scholars);
        }

        getAllScholars();
        loadSubjects();
    }, []);

    const updateSelectedScholar = id => {
        resetAll();
        setTimeout(() => {
            const found = allScholars.find(theScholar => theScholar.id === id);
            const newSelectedScholar = Object.assign({}, found);
            setSelectedScholar(newSelectedScholar);
        }, 200);
    }

    const resetAll = _ => {
        setSelectedScholar(null);
        setFormSubmitted(false);
    }

    const submitAssignScholarForm = async (selectedSubjects) => {
        const response = await updateScholar(selectedScholar.id, { subject: selectedSubjects});
        console.log('----response', response?.data);
        if (response?.data?.scholar) {
            const newScholar = response?.data?.scholar;
            
            const index = await allScholars.findIndex(sch => {
                return sch.id === selectedScholar.id;
            });

            if (index > -1) {
                const all = allScholars;
                all[index] = newScholar;
                setAllScholars(all);
                setSelectedScholar(newScholar);
            }

            message.success({
                content: 'All set', 
                style: { fontSize: '1.5em', backgroundColor: 'lightblue' },
                duration: 5
            });

            setFormSubmitted(true);
        } else {
            message.error({
                content: 'Something went wrong. Please check with Admin!', 
                style: { fontSize: '1.5em', backgroundColor: 'red' },
                duration: 5
            });
        }
    }

    return (
        <div style={styles.assignSubject}>
            {
                allScholars &&
                    <Scholars 
                        allScholars={allScholars}
                        updateScholar={updateSelectedScholar}
                    />
            }

            {
                !formSubmitted && selectedScholar &&
                    <ShowSelectedScholar
                        cname={selectedScholar.cname}
                        ename={selectedScholar.ename}
                        resetAll={resetAll}
                    />
            }

            {
                !formSubmitted && selectedScholar && 
                    <ShowAllSubjects
                        submitAssignScholarForm={submitAssignScholarForm}
                        scholar={selectedScholar}
                    />
            }

            {
                formSubmitted && selectedScholar && (
                    <React.Fragment>
                        <ShowSelectedScholar
                            cname={selectedScholar.cname}
                            ename={selectedScholar.ename}
                            resetAll={resetAll}
                            noClear={true}
                        />
                        <OneScholar 
                            scholar={selectedScholar}
                            widthStyle='100%'
                        />
                        <div>
                            <Button 
                                type="primary" 
                                onClick={resetAll}
                            >Next</Button>
                        </div>
                    </React.Fragment>
                )
            }
        </div>
    )
}

export default AssignSubject;