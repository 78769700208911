import React from 'react';

const styles = {
    showSelectedScholar: {
        padding: 20
    },
    clearButton: {
        display: 'inline-block',
        marginLeft: 50,
        color: '#ff0000',
        cursor: 'pointer'
    }
}

const ShowSelectedScholar = props => {
    return (
        <React.Fragment>
            <div style={styles.showSelectedScholar}>
                Selected: { props.cname } { props.ename }
                {
                    !props.noClear && 
                        <div style={styles.clearButton} onClick={props.resetAll.bind(this)}>Clear</div>
                }
            </div>
        </React.Fragment>
    )
}

export default ShowSelectedScholar;