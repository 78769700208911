import React from 'react';
import { Button } from 'antd';

import { logout } from '../utils/util';

const Logout = props => {
    return (
        <div>
            <Button 
                type="primary"
                onClick={logout}
            >Logout</Button>
        </div>
    )
}

export default Logout;