import React, { useState, useEffect } from 'react';

import { Checkbox, Modal } from 'antd';

const styles = {
    showAllSubjects: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%'
    },
    datetime: {
        marginBottom: 20,
        marginLeft: 20
    },
    childName: {
        marginLeft: 20
    }
}

const SelectRemainingSubjects = props => {
    const [ selectableSubjects, setSelectableSubjects ] = useState(null);
    const [ selectedSubjects, setSelectedSubjects ] = useState([]);
    const [ show, setShow ] = useState(false);
    const [ timeslotInfo, setTimeslotInfo ] = useState(null);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    useEffect(() => {
        if (props.timeslot) {
            const info = props.findTimeslotInfo(props.timeslot);
            setTimeslotInfo(info);
        }
    }, [props.timeslot]);

    useEffect(() => {
        if (props.selectableSubjects) {
            setSelectableSubjects(props.selectableSubjects);
        }
    }, [props.selectableSubjects]);

    useEffect(() => {
        return () => {
            setSelectedSubjects([]);
        }
    }, []);

    const isDisabled = id => {
        if (selectedSubjects.indexOf(id) > -1) {
            return false;
        }
        if (selectedSubjects.length >= 3) {
            return true;
        }
        return false;
    }

    const isDisableButton = _ => {
        if (selectableSubjects && selectableSubjects.length < 3) {
            return false;
        }
        return selectedSubjects.length < 3;
    }

    const handleCheckboxOnChange = event => {
        const { checked, value } = event.target;
        const selected = [...selectedSubjects];
        if (checked) {
            selected.push(value);
        } else {
            try {
                const index = selected.findIndex(val => val === value);
                selected.splice(index, 1);
            } catch (e) {}
        }
        setSelectedSubjects(selected);
    }

    const handleSelectByTimeslotSave = _ => {
        props.submitTimeslotSubjects(selectedSubjects);
    }

    const handleSelectByTimeslotCancel = _ => {
        props.submitTimeslotSubjects(null);
    }

    return (
        <Modal
            title="Please select 3 subjects for this timeslot"
            visible={show}
            onOk={handleSelectByTimeslotSave}
            onCancel={handleSelectByTimeslotCancel}
            okButtonProps={{ disabled: isDisableButton() }}
        >
            {
                timeslotInfo && (
                    <div style={styles.datetime}>{timeslotInfo.date} @ {timeslotInfo.time}</div>
                )
            }
            
            {
                selectableSubjects && selectableSubjects.length > 0 && (
                    <div style={styles.showAllSubjects}>
                        {  
                            selectableSubjects.map((val, y) => {
                                return (
                                    <Checkbox 
                                        key={y}
                                        style={styles.childName}
                                        value={val._id}
                                        onChange={handleCheckboxOnChange}
                                        disabled={isDisabled(val._id)}
                                    >
                                        {val.parent} - {val.name}
                                    </Checkbox>
                                )
                            })
                        }
                    </div>
                )
            }
        </Modal>
    )
}

export default SelectRemainingSubjects;