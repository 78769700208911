import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { loginUser } from '../api/api';
import { checkIfUserAlreadyLoggedIn } from '../utils/util';

const styles = {
  login: {
    display: 'flex',
    flexDirection: 'column',
    justifyContents: 'center',
    alignItems: 'center',
    padding: 40
  },
  title: {
    padding: 10
  },
  error: {
    color: '#ff0000'
  }
};

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const Login = props => {
  const [ username, setUsername ] = useState(null);
  const [ admin, setAdmin ] = useState(null);
  const [ loggedIn, setLoggedIn ] = useState(false);
  const [ error, setError ] = useState(null);
  const [ id, setId ] = useState(0);

  let history = useHistory();

  checkIfUserAlreadyLoggedIn({props, history, successRedirect: '/home'});

  const onFinish = async (values) => {
    // console.log('Success:', values);

    const { username, password } = values;
    const user = await loginUser({ username, password });

    if (user?.data) {
      const { login, token, username, admin, id } = user.data;

      if (login && username) {
        window.localStorage.setItem('token', token);
        setUsername(username);
        setAdmin(admin);
        setId(id);
        setLoggedIn(true);
      } else {
        setError('Please try again!');
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  if (loggedIn) {
    return <Redirect to={{
      pathname: "/home",
      state: {
          username: username,
          admin: admin,
          referrer: window.location+'',
          id: id
      }
    }} />
  }

  return (
    <div style={styles.login}>
      <h1 style={styles.title}>Please Login</h1>
      <Form
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={() => setError('')}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your username!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <span style={styles.error}>{error}</span>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;