import React, { useState, useEffect } from 'react';

const styles = {
    subject: {
        paddingLeft: 20,
        paddingRight: 20,
        // width: 400
    },
    subjectsMap: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    subjectsMapKey: {
        flex: 3,
        padding: 5
    },
    subjectsMapValue: {
        flex: 12,
        padding: 5,
        marginLeft: 30,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    subjectValue: {
        marginRight: 20
    }
}

const DisplaySubjectMap = props => {
    const [ subjectsMap, setSubjectsMap ] = useState(null);
    
    useEffect(() => {
        if (props.subjectsMap) {
            setSubjectsMap(props.subjectsMap);
        }
    }, [props.subjectsMap]);

    return (
        <div style={styles.subject}>
            Subjects:
            {
                subjectsMap && Object.keys(subjectsMap).map((key, y) => {
                    return (
                        <div key={y} style={styles.subjectsMap}>
                            <div style={styles.subjectsMapKey}>{key}</div>
                            <div style={styles.subjectsMapValue}>
                                {
                                    subjectsMap[key].length > 0 && subjectsMap[key].map((val, i) => <div key={i} style={styles.subjectValue}>{val}</div>)
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default DisplaySubjectMap;