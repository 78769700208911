import React from 'react';

const styles = {
    subjects: {
        marginTop: 10,
        padding: 10
    }
}

const OneSubject = props => {

    return (
        <div>
            <div>{props.time}</div>
            <div style={styles.subjects}>{props.subjects}</div>
        </div>
    )
}

export default OneSubject;